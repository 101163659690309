<template>
  <div
    id="sidebar-email-left"
    class="sidebar-left"
  >
    <div class="sidebar">
      <div class="sidebar-content email-app-sidebar">
        <div class="email-app-menu">
          <div class="form-group-compose text-center compose-btn">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              @click="$emit('showModalSend')"
            >
              Gửi tin nhắn
            </b-button>
          </div>
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area"
          >
            <!-- Filters -->
            <b-list-group class="list-group-messages">
              <b-list-group-item
                :to="{ name: 'apps-email' }"
                :active="isDynamicRouteActive({ name: 'apps-email' })"
                @click="$emit('close-left-sidebar')"
              >
                <feather-icon
                  icon="MailIcon"
                  size="18"
                  class="mr-75"
                />
                <span
                  class="align-text-bottom line-height-1"
                  @click="$emit('close-email-view')"
                >Hộp thư đến</span>
                <b-badge
                  v-if="dataCountNotify"
                  pill
                  variant="light-primary"
                  class="float-right"
                >
                  {{ dataCountNotify }}
                </b-badge>
              </b-list-group-item>
            </b-list-group>

            <!-- Filters -->
            <b-list-group class="list-group-messages">
              <b-list-group-item
                :to="{ name: 'apps-email-folder' }"
                :active="isDynamicRouteActive({ name: 'apps-email-folder' })"
                @click="$emit('close-left-sidebar')"
              >
                <feather-icon
                  icon="SendIcon"
                  size="18"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">Hộp thư đi</span>
              </b-list-group-item>
            </b-list-group>

          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  BButton, BListGroup, BListGroupItem, BBadge,
} from 'bootstrap-vue'
import { isDynamicRouteActive } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {

    // BSV
    BButton,
    BListGroup,
    BListGroupItem,
    BBadge,

    // 3rd Party
    VuePerfectScrollbar,
  },
  props: {
    // shallShowEmailComposeModal: {
    //   type: Boolean,
    //   required: true,
    // },
    emailsMeta: {
      type: Object,
      required: true,
    },
    newInbox: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    ...mapGetters('app', ['dataCountNotify']),
  },

  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    const emailLabel = [
      { title: 'Personal', color: 'success', route: { name: 'apps-email-label', params: { label: 'personal' } } },
      { title: 'Company', color: 'primary', route: { name: 'apps-email-label', params: { label: 'company' } } },
      { title: 'Important', color: 'warning', route: { name: 'apps-email-label', params: { label: 'important' } } },
      { title: 'Private', color: 'danger', route: { name: 'apps-email-label', params: { label: 'private' } } },
    ]

    const resolveFilterBadgeColor = filter => {
      if (filter === 'Draft') return 'light-warning'
      if (filter === 'Spam') return 'light-danger'
      return 'light-primary'
    }

    return {
      // UI
      perfectScrollbarSettings,
      isDynamicRouteActive,
      resolveFilterBadgeColor,

      // Filter & Labels
      emailLabel,
    }
  },
}
</script>

<style lang="scss">

  .email-application .content-area-wrapper .sidebar .list-group .list-group-item.active{
    border-color: #227FF4 !important
  }

</style>
