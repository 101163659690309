<template>
  <div id="importFileEmail">
    <div class="item-file-import-email shadow-lg p-1 bg-white rounded">
      {{ name }} - <span v-if="value < 100">{{ value }}%</span> <span v-if="value > 90">{{ size | formatSize }}</span>
      <feather-icon
        icon="XIcon"
        size="16"
        class="float-right cursor-pointer"
        @click="$emit('handleDelete')"
      />
    </div>
    <b-progress
      v-if="value<100"
      :value="value"
      :max="100"
    ></b-progress>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BForm,
  BFormInput,
  BAvatar,
  BDropdownDivider,
  BButton,
  BProgress,
} from 'bootstrap-vue'

export default {
  components: {
    BProgress,

  },
  filters: {
    formatSize(fileSizeInBytes) {
      let i = -1
      const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB']
      do {
        fileSizeInBytes /= 1024
        i++
      } while (fileSizeInBytes > 1024)

      return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i]
    },
  },
  props: {
    size: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      value: 0,
    }
  },
  created() {
    this.startTimer()
  },
  methods: {
    startTimer() {
      if (this.value < 100) {
        setTimeout(() => {
          this.value++
          this.startTimer()
        }, this.getRandomInt(3))
      }
    },
    getRandomInt() {
      return Math.floor(Math.random() * 90)
    },
  },
}
</script>

<style lang="scss">
 .file-import-email{
    // position: absolute;
    width: 95%;
    margin-left: 2%;
    margin-top: 10px;
    padding-bottom: 5px;
    // bottom: 40px;
      .item-file-import-email{
        width: 100%;
        margin-bottom: -10px;
      }
      .progress{
        height: 5px;
        margin-top: 6px;
      }
  }

</style>
