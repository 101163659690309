<template>
  <b-modal
    :id="id"
    title="Compose Email"
    :modal-class=" maxScreenEmail !== true ? 'modal-sticky' : '' "
    footer-class="d-flex justify-content-between"
    body-class="p-0"
    :size="sizeModal"
    no-fade
    no-close-on-backdrop
    static
    centered
    @show="filterUserId"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title">
        Tin nhắn mới
      </h5>
      <div class="modal-actions">
        <feather-icon
          icon="MinusIcon"
          class="cursor-pointer"
          @click="hiddenMinusModalEmail"
        />
        <feather-icon
          v-if="maxScreenEmail === false"
          icon="Maximize2Icon"
          class="ml-1 cursor-pointer"
          @click="maxScreen"
        />
        <feather-icon
          v-else
          icon="Minimize2Icon"
          class="ml-1 cursor-pointer"
          @click="maxScreen"
        />
        <feather-icon
          icon="XIcon"
          class="ml-1 cursor-pointer"
          @click="hiddenModalEmail"
        />
      </div>
    </template>

    <!-- Modal Footer -->
    <template #modal-footer>
      <!-- Footer: Left Content -->
      <div>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          :disabled="dataInput.receiveIds.length > 0 ? false : true"
          variant="primary"
          @click="sendEmail($event)"
        >
          Gửi
        </b-button>

        <feather-icon
          icon="PaperclipIcon"
          size="17"
          class="ml-2 cursor-pointer"
          @click="uploadFile"
        />
        <input
          ref="upload-file"
          type="file"
          class="d-none"
          multiple
          placeholder="hello"
          @change="importFile"
        />
      </div>

      <!-- Footer: Right Content -->

    </template>
    <validation-observer ref="rule">

      <!-- Modal: Body -->
      <b-form>
        <div
          class="compose-mail-form-field"
        >

          <label
            for="email-to"
            class="form-label"
          >Đến: </label>
          <!-- {{ dataInput.receiveIds }} -->
          <v-select
            v-model="dataInput.receiveIds"
            multiple
            :reduce="item => item"
            class="flex-grow-1 email-to-selector"
            :close-on-select="false"
            :options="emailToOptions"
            label="userName"
          >
            <!-- :search="item => item.fullName" -->
            <template #option="{ avatar, fullName, userName }">
              <b-avatar
                size="sm"
                :src="($serverfile+avatar)"
              />
              <span class="ml-50"> {{ fullName }}</span>
              <span
                class="ml-50"
              > ({{ userName }})</span>
            </template>
            <template
              #selected-option="{ avatar, fullName ,userName }"
              class="scroll-select-option"
            >
              <b-avatar
                size="sm"
                class="border border-white"
                :src="($serverfile+avatar) "
              />
              <span class="ml-50"> {{ fullName }}</span>
              <span
                class="ml-50"
              > {{ userName }}</span>
            </template>

          </v-select>
        </div>

        <!-- Field: Subject -->
        <validation-provider
          #default="{ errors }"
          name="subject"
          rules="required"
          :custom-messages="subject"
        >
          <div class="compose-mail-form-field">
            <label
              for="email-subject"
              class="compose-label"
            >Tiêu đề:</label>
            <b-form-input
              id="dataInput.subject"
              v-model="dataInput.subject"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>

          </div>
        </validation-provider>

        <!-- Field: Message - Quill Editor -->
        <div
          :class=" maxScreenEmail === true ? 'max-screen' : '' "
        >
          <div class="message-editor">
            <quill-editor
              id="quil-content"
              v-model="dataInput.content"
              :options="editorOption"
            />
            <div
              v-if="dataInput.modelFormData.length > 0"
              class="list-file-import"
            >
              <div
                v-for="(file,index ) in dataInput.modelFormData"
                :key="index"
                class="file-import-email"
              >
                <list-item-import
                  :name="file.fileName"
                  :size="file.size"
                  @handleDelete="handleDelete(index)"
                />
              </div>

            </div>
            <div
              id="quill-toolbar"
              class="d-flex border-bottom-0"
            >
              <!-- Add a bold button -->
              <button class="ql-bold" />
              <button class="ql-italic" />
              <button class="ql-underline" />
              <button class="ql-align" />
              <button class="ql-link" />
            </div>
          </div>
        </div>
      </b-form>
    </validation-observer>

  </b-modal>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BForm,
  BFormInput,
  BAvatar,
  BDropdownDivider,
  BButton,
  BProgress,
  VBScrollspyPlugin,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import { quillEditor } from 'vue-quill-editor'
import vSelect from 'vue-select'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ListItemImport from './ListItemImport.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '@/views/email/constant/ConstantsApi'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    // BSV
    BDropdown,
    BDropdownItem,
    BForm,
    BFormInput,
    BAvatar,
    BDropdownDivider,
    BProgress,
    VBScrollspyPlugin,
    // 3rd Party
    quillEditor,
    vSelect,
    ListItemImport,

    ValidationProvider,
    ValidationObserver,
  },

  props: {
    typeSend: {
      type: String,
      default: '',
    },
    fullDataDetail: {
      type: Object,
      default: _ => {},
    },
    id: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'inbox',
    },
  },
  setup(_, { emit }) {
    const composeData = ref({})
    const showCcField = ref(false)
    const showBccField = ref(false)

    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: 'Nhập nội dung…',
    }

    /* eslint-disable global-require */
    const emailToOptions = [
      { avatar: require('@/assets/images/avatars/1-small.png'), name: 'Jane Foster' },
      { avatar: require('@/assets/images/avatars/3-small.png'), name: 'Donna Frank' },
      { avatar: require('@/assets/images/avatars/5-small.png'), name: 'Gabrielle Robertson' },
      { avatar: require('@/assets/images/avatars/7-small.png'), name: 'Lori Spears' },
      { avatar: require('@/assets/images/avatars/9-small.png'), name: 'Sandy Vega' },
      { avatar: require('@/assets/images/avatars/11-small.png'), name: 'Cheryl May' },
    ]
    /* eslint-enable global-require */

    // const sendEmail = () => {
    //   composeData.value = {}
    //   emit('update:shall-show-email-compose-modal', false)

    //   // ? Send your Email
    // }

    const discardEmail = () => {
      composeData.value = {}
      emit('update:shall-show-email-compose-modal', false)
    }

    return {
      composeData,
      editorOption,
      emailToOptions,
      showCcField,
      showBccField,

      // Email actions
      // sendEmail,
      discardEmail,
    }
  },

  data() {
    return {
      required,
      receive: {
        required: 'Địa chỉ email là bắt buộc',
      },
      subject: {
        required: 'Tiêu đề email là bắt buộc',
      },
      dataAccounts: [],
      emailToOptions: [],
      dataInput: {
        receiveIds: [],
        subject: '',
        content: '',
        modelFormData: [],
        detail: null,
      },
      value: 0,
      max: 100,
      maxSiMum: false,
      sizeModal: 'lg',
      maxScreenEmail: false,
      receiveIds: [],
    }
  },
  watch: {
    // fullDataDetail(val) {
    //   if (val) {
    //     console.log(val)
    //   }
    // },
  },
  created() {
    this.fetchCombobox()
  },
  methods: {
    async fetchCombobox() {
      const { data } = await axiosApiInstance.get(ConstantsApi.COMBOBOX)
      this.emailToOptions = data
    },

    // Tải file lên
    uploadFile() {
      //
      this.$refs['upload-file'].click()
    },
    // Lấy dữ liệu từ file
    importFile(e) {
      const tmpFiles = e.target.files || e.dataTransfer.files
      if (!tmpFiles.length) { return }
      tmpFiles.forEach(x => {
        this.dataInput.modelFormData.push({
          files: x,
          isSecure: false,
          fileName: x.name,
          showFile: true,
          size: x.size,
        })
      })
    },
    filterUserId() {
      if (this.fullDataDetail) {
        const receiver = this.emailToOptions.find(x => x.id === this.fullDataDetail?.userInfo?.userId)
        if (receiver) {
          this.dataInput.receiveIds.push(receiver)
        }
      }
    },
    // Xử lí xoá file tải lên
    handleDelete(val) {
      const dataForm = this.dataInput.modelFormData[val]
      dataForm.showFile = false
      this.dataInput.modelFormData[val] = dataForm
      this.dataInput.modelFormData = this.dataInput.modelFormData.filter(e => e.showFile === true)
    },

    sendEmail(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.handleSendMail()
        }
      })
    },

    // Xử lí gửi mail
    async handleSendMail() {
      // const isSecure = this.dataInput.modelFormData.map(e => e.isSecure)
      const receiveId = this.dataInput.receiveIds.map(x => x.id)
      const file = this.dataInput.modelFormData.map(e => e.files)
      const formData = new FormData()
      if (this.dataInput.modelFormData) {
        receiveId.forEach(x => {
          formData.append('ReceiveIds', x)
        })
        formData.append('Subject', this.dataInput.subject)
        formData.append('Content', this.dataInput.content)
        formData.append('IsSecure', false)
        file.forEach(x => {
          formData.append('formFile', x)
        })
      } else {
        formData.append('ReceiveIds', this.dataInput.receiveIds)
        formData.append('Subject', this.dataInput.subject)
        formData.append('Content', this.dataInput.content)
      }
      this.$showAllPageLoading()
      const res = await axiosApiInstance.post(ConstantsApi.SEND_MAIL, formData)
      if (res.status === 200) {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Gửi thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.$bvModal.hide(this.id)
        this.dataInput = {
          receiveIds: [],
          subject: '',
          content: '',
          modelFormData: [],
        }
      } else {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Gửi thất bại', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      }
    },

    // tắt và reset data
    hiddenModalEmail() {
      this.maxScreenEmail = false
      this.sizeModal = 'lg'
      this.$bvModal.hide(this.id)
      this.dataInput = {
        receiveIds: [],
        subject: '',
        content: '',
        modelFormData: [],
        detail: null,
      }
    },

    resetData() {
      this.dataInput.detail = null
    },

    // ẩn modal
    hiddenMinusModalEmail() {
      this.$bvModal.hide(this.id)
      this.dataInput.receiveIds = []
    },

    maxScreen() {
      if (this.sizeModal === 'xl') { this.sizeModal = 'lg' } else { this.sizeModal = 'xl' }
      this.maxScreenEmail = !this.maxScreenEmail
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
form ::v-deep {
.vs__selected-options {
  overflow-y: overlay;
  max-height: 150px;
}

  // Mail To vue-select style
  .v-select {
    .vs__dropdown-toggle {
      border: 0;
      box-shadow: none;
    }
    .vs__open-indicator {
      display: none;
    }
  }

  // Quill Editor Style
  .quill-editor {
    .ql-container.ql-snow {
      border-bottom: 0 !important;
      height: 300px;
    }
  }
  @media (min-width: 1444px){
  .max-screen{
    .quill-editor {
      .ql-container.ql-snow {
      border-bottom: 0 !important;
      height: 70vh !important;
    }
  }
  }
}
  .max-screen{
    .quill-editor {
      .ql-container.ql-snow {
      border-bottom: 0 !important;
      height: 60vh !important;
    }
  }
  }
}
#compose-mail{
  .list-file-import {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  bottom: 40px;
  max-height: 200px;
  background-color: #ffff;
  Overflow-x:scroll;

  }

  .modal-dialog {
    max-width: 100%;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
  }

}

.compose-label{
white-space: nowrap ;
}

</style>
