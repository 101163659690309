export default {
  // gửi mail
  SEND_MAIL: 'Message/send-email',

  // hộp thư đến
  API_GET_INBOX: 'Message/getInbox',
  DETAIL_GET_INBOX: 'Message/inboxDetail',
  DELETE_INBOX: 'Message/deleteInbox',
  QUANTITY_INBOX: 'Message/getCountNewInbox',

  // hộ thư đi
  API_GET_SEND: 'Message/getSent',
  DETAIL_GET_SEND: 'Message/sentDetail',
  DELETE_SEND: 'Message/deleteSent',

  // Combobox
  COMBOBOX: 'Message/accounts',

  // Đánh dấu đã đọc mail
  CHECK_READ: 'Message/check-read',
}
