<template>
  <div class="email-app-details">

    <!-- Email Header -->
    <div class="email-detail-header">

      <!-- Header: Left -->
      <div class="email-header-left d-flex align-items-center">
        <span class="go-back mr-1">
          <feather-icon
            icon="ChevronLeftIcon"
            size="20"
            class="align-bottom"
            @click="$emit('close-email-view')"
          />
        </span>
        <h4 class="email-subject mb-0">
          {{ emailViewData.subject }}
        </h4>
      </div>

      <!-- Header: Right -->
      <div class="email-header-right ml-2 pl-1">

        <!-- Move to Trash -->
        <feather-icon
          icon="TrashIcon"
          class="ml-75 cursor-pointer"
          size="17"
          @click="deleteItems"
        />
        |
        <!-- Show Previous Mail -->
        <!-- <feather-icon
          :icon="$store.state.appConfig.isRTL ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
          size="17"
          class="ml-75 cursor-pointer"
          :class="{'text-muted pointer-events-none': !opendedEmailMeta.hasPreviousEmail}"
          @click="$emit('change-opened-email', 'previous')"
        /> -->

        <!-- Show Next Mail -->
        <!-- <feather-icon
          :icon="$store.state.appConfig.isRTL ? 'ChevronLeftIcon' : 'ChevronRightIcon'"
          size="17"
          class="ml-75 cursor-pointer"
          :class="{'text-muted pointer-events-none': !opendedEmailMeta.hasNextEmail}"
          @click="$emit('change-opened-email', 'next')"
        /> -->
      </div>
    </div>

    <!-- Email Details -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="email-scroll-area scroll-area"
    >

      <!-- Label Row -->
      <!-- <b-row>
        <b-col cols="12">
          <div class="email-label">
            <b-badge
              v-for="(label) in emailViewData.labels"
              :key="label"
              pill
              class="text-capitalize mr-50"
              :variant="`light-${resolveLabelColor(label)}`"
            >
              {{ label }}
            </b-badge>
          </div>
        </b-col>
      </b-row> -->

      <!-- Action: Show Earlier Message -->
      <b-row
        v-if="!showWholeThread && emailViewData.replies && emailViewData.replies.length"
        class="mb-1 email-label"
      >
        <b-col cols="12">
          <b-link
            class="font-weight-bold"
            @click="showWholeThread = true"
          >
            View {{ emailViewData.replies.length }} Earlier Message{{ emailViewData.replies.length > 1 ? 's' : '' }}
          </b-link>
        </b-col>
      </b-row>

      <!-- Email Thread -->
      <b-row class="mt-2">
        <b-col cols="12">
          <email-message-card
            :type="type"
            :message="emailViewData"
            @showModalRepMail="showModalRepMail"
            @deleteEmail="deleteItems"
          />
        </b-col>
      </b-row>

      <!-- Email Actions: Reply or Forward -->
      <b-row v-if="type ==='inbox'">
        <b-col cols="12">
          <b-card>
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">
                Bấm vào đây
                <b-link @click="$emit('showModalSendMail')">
                  Trả lời
                </b-link>
              </h5>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </vue-perfect-scrollbar>

    <!-- modal xóa mail-->
    <confirm-modal
      :id="confirmModalId"
      :content="modalContent"
      @accept="deleteAction"
    />
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BBadge,
  BCard,
  BLink,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { ref, watch } from '@vue/composition-api'
import { mapActions, mapGetters } from 'vuex'
import useEmail from '../../store/useEmail'
import EmailMessageCard from './EmailMessageCard.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constant/ConstantsApi'

export default {
  components: {

    // BSV
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BBadge,
    BCard,
    BLink,
    ConfirmModal,
    // 3rd Party
    VuePerfectScrollbar,

    // SFC
    EmailMessageCard,

  },
  props: {
    type: {
      type: String,
      default: 'inbox',
    },
    emailViewData: {
      type: Object,
      default: _ => {},
    },

  },

  data() {
    return {
      modalContent: '',
      // Id modal xoá
      confirmModalId: 'confirm-modal-delete-email-detail',
      deleteIds: [],
    }
  },
  setup(props) {
    const { resolveLabelColor } = useEmail()

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const showWholeThread = ref(false)

    watch(() => '', () => {
      showWholeThread.value = false
    })

    return {

      //
      perfectScrollbarSettings,
      showWholeThread,

      // useEmail
      resolveLabelColor,
    }
  },

  methods: {
    // Hiển thị modal xoá người dùng
    deleteItems() {
      this.deleteIds.push(this.emailViewData.id)
      this.modalContent = this.$t('Bạn có muốn xóa không?')
      this.$bvModal.show(this.confirmModalId)
    },

    async deleteAction() {
      this.$showAllPageLoading()
      await axiosApiInstance.post(this.type === 'inbox' ? ConstantsApi.DELETE_INBOX : ConstantsApi.DELETE_SEND, this.deleteIds).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        // window.location.reload()
        this.$emit('close-email-view')
        this.$emit('reload-table')
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xoá không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    showModalRepMail() {
      this.$emit('showModalRepEmail')
    },
  },
}
</script>

<style>

</style>
