<template>
  <div id="cardMessage">
    <b-card no-body>
      <b-card-header
        v-if="message.userInfo || message.users"
        class="email-detail-head"
      >
        <div class="user-details d-flex justify-content-between align-items-center flex-wrap">
          <b-avatar
            v-if="message.userInfo"
            size="48"
            :src="($serverfile+message.userInfo.avatar)"
            class="mr-75"
          />
          <div
            v-if="type === 'inbox'"
            class="mail-items"
          >
            <h5

              class="mb-0"
            >
              {{ message.userInfo.fullName }}
            </h5>

            <b-dropdown
              variant="link"
              no-caret
              toggle-class="p-0"
              disabled
            >
              <template #button-content>
                <span class="font-small-3 text-muted mr-25">{{ message.userInfo.email }}</span>

              </template>

            </b-dropdown>
          </div>
          <div
            v-for="(user,index) in message.users "
            v-else
            :key="index"
            class="mail-items"
          >
            <h5

              class="mb-0"
            >
              {{ user.fullName }}
            </h5>

            <b-dropdown
              variant="link"
              no-caret
              toggle-class="p-0"
              disabled
            >
              <template #button-content>
                <span class="font-small-3 text-muted mr-25">{{ user.email }}</span>

              </template>

            </b-dropdown>
          </div>
        </div>
        <div class="mail-meta-item d-flex align-items-center">
          <small class="mail-date-time text-muted">{{ message.sentDate | formatDateToDDMMHHMM }}</small>
          <!-- Mail Action DD -->
          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0"
            right
          >
            <template
              v-if="type ==='inbox'"
              #button-content
            >

              <feather-icon
                icon="MoreVerticalIcon"
                size="17"
                class="ml-50 text-body"
              />
            </template>

            <b-dropdown-item
              @click="logModal"
            >
              <feather-icon icon="CornerUpLeftIcon" />
              <span
                class="align-middle ml-50"
              >Trả lời</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="handlDelete"
            >
              <feather-icon
                icon="TrashIcon"
              />
              <span class="align-middle ml-50">Xóa</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </b-card-header>

      <b-card-body class="mail-message-wrapper pt-2">
        <!-- eslint-disable vue/no-v-html -->
        <div
          class="mail-message"
          v-html="message.content"
        >
        </div>
      <!-- eslint-enable -->
      </b-card-body>

    </b-card>
    <div

      class="card-deck"
    >
      <b-col
        v-for="(file, index) in message.filePaths"
        :key="index"
        style="width: 33%"
      >
        <a :href="$serverfile+file.filePath">

          <div
            id="list-items-email-download"
            class="card mb-3"
            style="max-width: 540px;"
          >
            <div class="row g-0 ">
              <div class="download-attach-email-backgroud">
                <div class="download-attach-email center">
                  <feather-icon
                    icon="DownloadIcon"
                    size="24"
                    class="download-icon-attach-email text-white"
                  />
                </div>
              </div>
              <div class="col-md-8 items-download">
                <div class="card-body">
                  <h5 class="card-title text-truncate">
                    {{ file.fileName }}
                  </h5>

                </div>
              </div>
            </div>
          </div>
        </a>
      </b-col>

    </div>
    <b-card v-if="message.attachments && message.attachments.length">
      <div class="mail-attachments">
        <div class="d-flex align-items-center mb-1">
          <feather-icon
            icon="PaperclipIcon"
            size="16"
          />
        </div>
      </div>
    </b-card>
  </div>

</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BCard,
  BCardHeader,
  BCardBody,
  BCardFooter,
  BAvatar,
  BLink,
  BImg,
  BCol,
} from 'bootstrap-vue'
import {
  formatDate,
  filterTags,
  formatDateToMonthShort,
  formatFullName,
  formatDateToDDMMHHMM,
} from '@core/utils/filter'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BCard,
    BCardHeader,
    BCardBody,
    BCardFooter,
    BAvatar,
    BLink,
    BImg,
    BCol,
  },
  filters: {
    formatDate,
    filterTags,
    formatDateToMonthShort,
    formatFullName,
    formatDateToDDMMHHMM,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: '',
    },
  },

  methods: {
    handlDelete() {
      this.$emit('deleteEmail')
    },

    logModal() {
      this.$emit('showModalRepMail')
    },
  },

}
</script>

<style lang="scss" >

#cardMessage{
  .row{
    flex-wrap: unset;
  }
.download-attach-email-backgroud{
  background-color: #6c757d0e;
  margin-left: 14px;
  border-radius: 6px;
  .items-download{
    padding-left: 0;
  }
  .download-attach-email{
    margin: 10px;
    width: 72px;
    height: 72px;
    border-radius: 100px;
    background-color: #82868B;
    .download-icon-attach-email{
      margin: 24px;
    }
  }
}

  .center {
    margin: 12px;
  }
}
</style>
